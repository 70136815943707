import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bb4b808e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "main-content"
};
import Introduction from '@/components/FunctionIntroduction';
import i18n from '@/language/index';
import { ref } from 'vue';
export default {
  __name: 'Home',
  setup(__props) {
    // import Header from '@/components/Header'
    // import PageFooter from '@/components/PageFooter'
    const enUrl = ref({
      one: require('@/assets/images/banner3.png'),
      two: require('@/assets/images/banner4.png'),
      three: require('@/assets/images/banner5.jpg'),
      four: require('@/assets/images/banner6.png'),
      five: require('@/assets/images/banner8.png'),
      six: require('@/assets/images/banner9.jpg')
    });
    const cnUrl = ref({
      one: require('@/assets/enImages/banner3.png'),
      two: require('@/assets/enImages/banner4.png'),
      three: require('@/assets/enImages/banner5.jpg'),
      four: require('@/assets/enImages/banner6.jpg'),
      five: require('@/assets/enImages/banner8.png'),
      six: require('@/assets/enImages/banner9.jpg')
    });
    // export default {
    //   name: 'Home',
    //   components: {
    //     Header,
    //     PageFooter,
    //     Introduction
    //   }
    // }

    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_el_carousel_item = _resolveComponent("el-carousel-item");
      const _component_el_carousel = _resolveComponent("el-carousel");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_carousel, {
        interval: 5000,
        arrow: "always",
        trigger: "click"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/CopyrightProtection"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.one : cnUrl.value.one,
              alt: ""
            }, null, 8, _hoisted_1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/Artalk"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.four : cnUrl.value.four,
              alt: ""
            }, null, 8, _hoisted_2)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/CrePoster"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.five : cnUrl.value.five,
              alt: ""
            }, null, 8, _hoisted_3)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/CrePoster"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.six : cnUrl.value.six,
              alt: ""
            }, null, 8, _hoisted_4)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/AIHeuristicCard/Home"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.three : cnUrl.value.three,
              alt: ""
            }, null, 8, _hoisted_5)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_carousel_item, null, {
          default: _withCtx(() => [_createVNode(_component_router_link, {
            to: "/Resources"
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: _unref(i18n).global.locale.value === 'zh' ? enUrl.value.two : cnUrl.value.two,
              alt: ""
            }, null, 8, _hoisted_6)]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_7, [_createVNode(_unref(Introduction))])], 64);
    };
  }
};