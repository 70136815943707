import { ElMessage } from 'element-plus'

// 节流函数，比如用在窗口大小改变的事件
export function throttle (fn, interval) {
  // last为上一次触发回调的时间
  let last = 0
  // 将throttle处理结果当作函数返回
  return function () {
    // 保留调用时的this上下文
    const context = this
    // 保留调用时传入的参数
    const args = arguments
    // 记录本次触发回调的时间
    const now = +new Date()
    // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
    if (now - last >= interval) {
      // 如果时间间隔大于我们设定的时间间隔阈值，则执行回调
      last = now
      fn.apply(context, args)
    }
  }
}

export function formatSize (res) {
  const docEl = document.documentElement
  const clientWidth = window.innerWidth || docEl.clientWidth || document.body.clientWidth
  if (!clientWidth) return
  const fontSize = 100 * (clientWidth / 1920)
  return (res / 100) * fontSize
}

/**
 *
 * @param {file} file 需要转换的图片file
 * @param {boolean} isCompression 是否返回压缩后的base64
 * @param {func} callback 转换成功后通过回调函数将结果返回
 */
export function imageToBase64 (file, isCompression, callback) {
  // 判断图片类型
  const isJpg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'

  // 判断图片大小
  // const isLt2M = file.size / 1024 / 1024 < 2
  if (!isJpg) {
    ElMessage.error('上传图片只能是jpg/png/jepg格式')
  }
  // if (!isLt2M) {
  //   ElMessage.error('上传图片大小不能超过2M')
  // }

  // 创建一个HTML5的FileReader对象
  const reader = new FileReader()
  // 创建一个img对象
  const img = new Image()
  // let filename = file.filename
  if (file) {
    reader.readAsDataURL(file)
  }
  if (isJpg) {
    let targetHeight
    reader.onload = (e) => {
      // let base64Str = reader.result.split[','][1]
      img.src = e.target.result
      // base64地址图片加载完毕后执行
      img.onload = function () {
        // 缩放图片需要canvas（也可以在DOM中直接定义canvas标签，这样就能把压缩完的图片不转base64也能直接显示出来）
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        // 图片原始尺寸
        const originWidth = this.width
        const originHeight = this.height
        // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
        const maxWidth = 800
        const maxHeight = 800
        // 目标尺寸
        let targetWidth = originWidth
        targetHeight = originHeight
        // 图片尺寸超过最大尺寸限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // 更改宽度，按照宽度限定尺寸
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
          } else {
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
          }
        }
        // 对图片进行缩放
        canvas.width = targetWidth
        canvas.height = targetHeight
        // 清除画布
        context.clearRect(0, 0, targetWidth, targetHeight)
        /** 图片压缩
         * 第一个参数是创建的img对象
         * 第二三个参数是左上角坐标
         * 后两个参数是画布区域宽高
         */
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        /** 压缩后的base64文件
         * 第一个参数可以为image/jpeg或image/webp类型的图片
         * 第二个参数设置图片质量取值0-1，超出则以默认值0.92替代
         */
        const newUrl = canvas.toDataURL('image/jpeg', 0.8)
        if (isCompression) { // 返回压缩后的base64
          callback(newUrl)
        } else { // 返回不压缩的base64
          callback(e.target.result)
        }
      }
    }
  }
}

export function arrayBufferToBase64 (buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  // window.btoa():将ascii字符串或二进制数据转换成一个base64编码过的字符串,该方法不能直接作用于Unicode字符串.
  return window.btoa(binary)
}
